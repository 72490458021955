import "@/assets/styles/base.css";
// 关于路由
import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";
import AuthRoute from "./components/AuthRoute";
import SpinLoding from "./components/SpinLoding";
import AsyncSpinLoding from "./components/AsyncSpinLoding";
import { Image } from "antd";
import { useSelector } from "react-redux";
import store, { RootState } from "./store";
import UpAsyncLoding from "./components/UpAsyncLoding";
import MessageCom from "./components/Message";
import LookDom from "./components/LookDom";
const Layout = React.lazy(() => import("./pages/Layout"));
const Login = React.lazy(() => import("./pages/Login"));

export default function App() {
  // 从仓库中获取查看图片的信息
  const lookBigImg = useSelector(
    (state: RootState) => state.A0Layout.lookBigImg
  );

  return (
    <>
      {/* 关于路由 */}
      <Router history={history}>
        <React.Suspense fallback={<SpinLoding />}>
          <Switch>
            <Route path="/login" component={Login} />
            <AuthRoute path="/" component={Layout} />
          </Switch>
        </React.Suspense>
      </Router>

      {/* 发送请求的加载组件 */}
      <AsyncSpinLoding />

      {/* 所有图片点击预览查看大图 */}
      <Image
        preview={{
          visible: lookBigImg.show,
          src: lookBigImg.url,
          onVisibleChange: (value) => {
            // 清除仓库信息
            store.dispatch({
              type: "layout/lookBigImg",
              payload: { url: "", show: false },
            });
          },
        }}
      />

      {/* 上传附件的进度条元素 */}
      <UpAsyncLoding />

      {/* 查看视频音频 */}
      <LookDom />

      {/* antd 轻提示 ---兼容360浏览器 */}
      <MessageCom />
    </>
  );
}
