import { hasToken } from "@//utils/storage";
import { MessageFu } from "@/utils/message";
import React from "react";
import { Redirect, Route } from "react-router-dom";

type AtahType = {
  path: string;
  component: React.FC;
  [x: string]: any;
};

export default function AuthRoute({ path, component: Com, ...rest }: AtahType) {
  return (
    <Route
      path={path}
      {...rest}
      render={() => {
        if (hasToken()) return <Com />;
        else {
          MessageFu.warning("登录失效!");
          return (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          );
        }
      }}
    />
  );
}
