import styles from "./index.module.scss";
import { Spin } from "antd";
import React from "react";

function AsyncSpinLoding() {
  return (
    <div id="AsyncSpinLoding" className={styles.AsyncSpinLoding}>
      <Spin size="large" />
    </div>
  );
}

const MemoAsyncSpinLoding = React.memo(AsyncSpinLoding);

export default MemoAsyncSpinLoding;
