import store, { RootState } from "@/store";
import { Button } from "antd";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import styles from "./index.module.scss";
function UpAsyncLoding() {
  // 从仓库中获取取消上传的函数
  const closeUpFile = useSelector(
    (state: RootState) => state.A0Layout.closeUpFile
  );

  const btnClose = useCallback(() => {
    closeUpFile.fu();

    setTimeout(() => {
      store.dispatch({
        type: "layout/closeUpFile",
        payload: { fu: () => {}, state: false },
      });
    }, 200);
  }, [closeUpFile]);

  return (
    <div id="UpAsyncLoding" className={styles.UpAsyncLoding}>
      <div className="progressBox">
        <div id="progress"></div>
      </div>
      {/* 手动取消上传按钮 */}
      <div className="closeUpBtn">
        <Button onClick={btnClose}>取消上传</Button>
      </div>
    </div>
  );
}

const MemoUpAsyncLoding = React.memo(UpAsyncLoding);

export default MemoUpAsyncLoding;
