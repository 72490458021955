import React, { useEffect } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

function MessageCom() {
  // 从仓库中获取 antd 轻提示信息
  const messageReducerInfo = useSelector(
    (state: RootState) => state.A0Layout.message
  );

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (messageReducerInfo.txt) {
      messageApi.open({
        type: messageReducerInfo.type,
        content: messageReducerInfo.txt,
        duration: messageReducerInfo.duration,
      });
    }
  }, [messageApi, messageReducerInfo]);

  return <>{contextHolder}</>;
}

const MemoMessage = React.memo(MessageCom);

export default MemoMessage;
