// import 'default-passive-events';

import App from "./App";
import store from "./store/index";

import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

import { ConfigProvider } from "antd";

// 兼容360浏览器
import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from "@ant-design/cssinjs";

import "dayjs/locale/zh-cn";
import locale from "antd/locale/zh_CN";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <ConfigProvider
    locale={locale}
    theme={{
      token: {
        colorPrimary: " #589498",
      },
    }}
  >
    <Provider store={store}>
      <StyleProvider
        hashPriority="high"
        transformers={[legacyLogicalPropertiesTransformer]}
      >
        <App />
      </StyleProvider>
    </Provider>
  </ConfigProvider>
);
