import axios from "axios";
import history from "./history";
import { getTokenInfo, removeTokenInfo } from "./storage";
import store from "@/store";
import { MessageFu } from "./message";
import { domShowFu } from "./domShow";

const envFlag = process.env.NODE_ENV === "development";

const baseUrlTemp = "https://sit-visit.4dage.com"; // 测试环境
// const baseUrlTemp = "http://192.168.20.61:8068"; // 线下环境

const baseFlag = baseUrlTemp.includes("https://");

// 请求基地址
export const baseURL = envFlag
  ? `${baseUrlTemp}${baseFlag ? "" : "/api-visit/"}`
  : "";

// 处理  类型“AxiosResponse<any, any>”上不存在属性“code”
declare module "axios" {
  interface AxiosResponse {
    code: number;
    timestamp: string;
    // 这里追加你的参数
  }
}

// 创建 axios 实例
const http = axios.create({
  baseURL: `${baseURL}${baseFlag ? "/api-visit/" : ""}`,
  timeout: 10000,
});

let axajInd = 0;

// 请求拦截器
http.interceptors.request.use(
  function (config: any) {
    // 发请求前打开加载提示
    domShowFu("#AsyncSpinLoding", true);

    axajInd++;

    const { token } = getTokenInfo();
    if (token) config.headers.token = token;
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

let timeId = -1;

// 响应拦截器
http.interceptors.response.use(
  function (response) {
    // 请求回来的关闭加载提示
    axajInd--;
    if (axajInd === 0) {
      domShowFu("#AsyncSpinLoding", false);
    }
    if (response.data.code === 5001 || response.data.code === 5002) {
      removeTokenInfo();
      history.push("/login");
      clearTimeout(timeId);
      timeId = window.setTimeout(() => {
        MessageFu.warning("登录失效!");
      }, 200);
    } else if (response.data.code === 0) {
      // MessageFu.success(response.data.msg);
    } else if (response.data.code !== 3014)
      MessageFu.warning(response.data.msg);

    return response.data;
  },
  async function (err) {
    clearTimeout(timeId);
    timeId = window.setTimeout(() => {
      axajInd = 0;
      domShowFu("#AsyncSpinLoding", false);
      // 如果因为网络原因，response没有，给提示消息
      if (!err.response) {
        if (store.getState().A0Layout.closeUpFile.state)
          MessageFu.warning("取消上传!");
        else MessageFu.error("网络繁忙，请稍后重试!");
      } else {
        if (
          err.response &&
          err.response.data &&
          err.response.data.msg &&
          err.response.data.msg.length < 30
        ) {
          MessageFu.error(err.response.data.msg);
          // 没有权限
          if (err.response.data.code === 5003) {
            removeTokenInfo();
            history.push("/login");
          }
        } else MessageFu.error("响应错误，请联系管理员!");
      }
    }, 100);

    return Promise.reject(err);
  }
);

// 导出 axios 实例
export default http;
