import { A6tableType } from "@/types";

// 初始化状态
const initState = {
  // 列表数据
  tableInfo: {
    list: [] as A6tableType[],
    total: 0,
  },
};

// 定义 action 类型
type Props = {
  type: "A6/getList";
  payload: { list: A6tableType[]; total: number };
};

// reducer
export default function Reducer(state = initState, action: Props) {
  switch (action.type) {
    // 获取列表数据
    case "A6/getList":
      return { ...state, tableInfo: action.payload };

    default:
      return state;
  }
}
