// 导入合并reducer的依赖
import { combineReducers } from "redux";

// 导入 登录 模块的 reducer
import A0Layout from "./layout";
import A1wxuser from "./A1wxuser";
import A2integral from "./A2integral";
import A3record from "./A3record";
import A4store from "./A4store";
import A5cash from "./A5cash";
import A6message from "./A6message";
import A7school from "./A7school";
import Z1user from "./Z1user";
import Z2log from "./Z2log";
import Z3visitStatistics from "./Z3visitStatistics";

// 合并 reducer
const rootReducer = combineReducers({
  A0Layout,
  A1wxuser,
  A2integral,
  A3record,
  A4store,
  A5cash,
  A6message,
  A7school,
  Z1user,
  Z2log,
  Z3visitStatistics,
});

// 默认导出
export default rootReducer;
