// ------------------------------------token的本地存储------------------------------------

// 用户 Token 的本地缓存键名，自己定义
const TOKEN_KEY = "WXCS_HT_USER_INFO";

/**
 * 从本地缓存中获取 用户 信息
 */
export const getTokenInfo = (): any => {
  return JSON.parse(localStorage.getItem(TOKEN_KEY) || "{}");
};

/**
 * 将 用户 信息存入缓存
 * @param {Object} tokenInfo 从后端获取到的 Token 信息
 */
export const setTokenInfo = (tokenInfo: any): void => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(tokenInfo));
};

/**
 * 删除本地缓存中的 用户 信息
 */
export const removeTokenInfo = (): void => {
  localStorage.removeItem(TOKEN_KEY);
};

/**
 * 判断本地缓存中是否存在 Token 信息
 */
export const hasToken = (): boolean => {
  return Boolean(getTokenInfo().token);
};

/**
 * 获取本地缓存中是否存在 Token 信息
 */
export const getTokenFu = (): string => {
  return getTokenInfo().token;
};
