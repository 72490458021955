import { LookDomType } from "@/types";
import { MessageType } from "@/utils/message";

// 初始化状态
const initState = {
  // 所有图片点击预览查看大图
  lookBigImg: {
    url: "",
    show: false,
  },
  // 查看视频、音频、模型
  lookDom: {
    src: "",
    type: "",
  } as LookDomType,

  // antd轻提示(兼容360浏览器)
  message: {
    txt: "",
    type: "info",
    duration: 3,
  } as MessageType,
  // 上传文件点击取消
  closeUpFile: {
    fu: () => {},
    state: false,
  },
};

// 定义 action 类型
type LayoutActionType =
  | { type: "layout/lookBigImg"; payload: { url: string; show: boolean } }
  | { type: "layout/lookDom"; payload: LookDomType }
  | { type: "layout/message"; payload: MessageType }
  | {
      type: "layout/closeUpFile";
      payload: {
        fu: () => void;
        state: boolean;
      };
    };

// 频道 reducer
export default function layoutReducer(
  state = initState,
  action: LayoutActionType
) {
  switch (action.type) {
    // 所有图片点击预览查看大图
    case "layout/lookBigImg":
      return { ...state, lookBigImg: action.payload };
    // 查看视频
    case "layout/lookDom":
      return { ...state, lookDom: action.payload };

    // antd轻提示(兼容360浏览器)
    case "layout/message":
      return { ...state, message: action.payload };
    // 上传文件点击取消
    case "layout/closeUpFile":
      return { ...state, closeUpFile: action.payload };
    default:
      return state;
  }
}
