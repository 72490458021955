/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import store, { RootState } from "@/store";
import { baseURL } from "@/utils/http";
function LookDom() {
  const { src, type, flag } = useSelector(
    (state: RootState) => state.A0Layout.lookDom
  );
  return (
    <div
      className={styles.LookDom}
      style={src ? { opacity: 1, pointerEvents: "auto" } : {}}
    >
      {src ? (
        <>
          {type === "video" ? (
            <div className="viedoBox">
              <video autoPlay controls src={flag ? src : baseURL + src}></video>
            </div>
          ) : type === "audio" ? (
            <div className="audioBox">
              <audio autoPlay controls src={flag ? src : baseURL + src}></audio>
            </div>
          ) : (
            <div className="modelBox">
              <iframe src={`model.html?m=${src}`}></iframe>
            </div>
          )}

          <div
            className="close"
            onClick={() =>
              store.dispatch({
                type: "layout/lookDom",
                payload: { src: "", type: "", flag: false },
              })
            }
          >
            <CloseCircleOutlined rev={undefined} />
          </div>
        </>
      ) : null}
    </div>
  );
}

const MemoLookDom = React.memo(LookDom);

export default MemoLookDom;
