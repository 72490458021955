import { A7tableType } from "@/types";

// 初始化状态
const initState = {
  // 列表数据
  tableInfo1: {
    list: [] as A7tableType[],
    total: 0,
  },
  tableInfo2: {
    list: [] as A7tableType[],
    total: 0,
  },
};

// 定义 action 类型
type Props =
  | {
      type: "A7/getList1";
      payload: { list: A7tableType[]; total: number };
    }
  | {
      type: "A7/getList2";
      payload: { list: A7tableType[]; total: number };
    };

// reducer
export default function Reducer(state = initState, action: Props) {
  switch (action.type) {
    // 获取列表数据
    case "A7/getList1":
      return { ...state, tableInfo1: action.payload };
    case "A7/getList2":
      return { ...state, tableInfo2: action.payload };

    default:
      return state;
  }
}
