import styles from "./index.module.scss";
import { Spin } from "antd";
import React from "react";
function SpinLoding() {
  return (
    <div className={styles.SpinLoding}>
      <Spin size='large'/>
    </div>
  );
}
const MemoSpinLoding = React.memo(SpinLoding);

export default MemoSpinLoding;