import store from "@/store";

export type MessageType = {
  txt: string;
  type: "info" | "success" | "error" | "warning";
  duration: number;
};

export const MessageFu = {
  info: (txt: string, duration?: number) => {
    store.dispatch({
      type: "layout/message",
      payload: {
        txt,
        type: "info",
        duration: duration === undefined ? 3 : duration,
      },
    });
  },
  success: (txt: string, duration?: number) => {
    store.dispatch({
      type: "layout/message",
      payload: {
        txt,
        type: "success",
        duration: duration === undefined ? 3 : duration,
      },
    });
  },
  error: (txt: string, duration?: number) => {
    store.dispatch({
      type: "layout/message",
      payload: {
        txt,
        type: "error",
        duration: duration === undefined ? 3 : duration,
      },
    });
  },
  warning: (txt: string, duration?: number) => {
    store.dispatch({
      type: "layout/message",
      payload: {
        txt,
        type: "warning",
        duration: duration === undefined ? 3 : duration,
      },
    });
  },
};
