// 导入 redux
import { applyMiddleware, legacy_createStore as createStore } from 'redux'
// 导入自己封装的  rootReducer 
import rootReducer from './reducer'
// 导入调试工具和 异步的 redux（用来发送异步请求）
// 调试工具需要下载谷歌 扩展程序 我用的是 Redux DevTools 3.0.17
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

// 创建仓库实例
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

// 声明 RootState，在使用仓库的时候用来使用
export type RootState = ReturnType<typeof store.getState>

// 声明 AppDispatch，在异步请求的时候来使用
export type AppDispatch = typeof store.dispatch

// 导出仓库实例
export default store