import { A2tableType } from "@/types";

// 初始化状态
const initState = {
  // 列表数据
  tableInfo: {
    list1: [] as A2tableType[],
    list2: [] as A2tableType[],
    list3: [] as A2tableType[],
  },
};

// 定义 action 类型
type Props = {
  type: "A2/getList";
  payload: { list1: A2tableType[]; list2: A2tableType[]; list3: A2tableType[] };
};

//  reducer
export default function Reducer(state = initState, action: Props) {
  switch (action.type) {
    // 获取列表数据
    case "A2/getList":
      return { ...state, tableInfo: action.payload };

    default:
      return state;
  }
}
