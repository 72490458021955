import { visitStatisticsTableType } from "@/types";

// 初始化状态
const initState = {
  // 列表数据
  tableInfo: {
    list: [] as visitStatisticsTableType[],
    total: 0,
  },
};

// 定义 action 类型
type Props = {
  type: "Z3/visitStatistics";
  payload: { list: visitStatisticsTableType[]; total: number };
};

// 频道 reducer
export default function visitStatisticsReducer(state = initState, action: Props) {
  switch (action.type) {
    // 获取列表数据
    case "Z3/visitStatistics":
      return { ...state, tableInfo: action.payload };
    default:
      return state;
  }
}
