import store from "@/store";

// 加载和上传的盒子的显示隐藏
export const domShowFu = (ele: string, val: boolean) => {
  const dom: HTMLDivElement = document.querySelector(ele)!;
  if (val) {
    dom.style.opacity = "1";
    dom.style.pointerEvents = "auto";
  } else {
    dom.style.opacity = "0";
    dom.style.pointerEvents = "none";
  }
};

// 上传附件的进度条
let progressDom: HTMLDivElement = document.querySelector("#progress")!;
export const progressDomFu = (val: string) => {
  if (!progressDom) progressDom = document.querySelector("#progress")!;
  progressDom.style.width = val;
};

// 上传附件的dom操作
export const fileDomInitialFu = () => {
  // 隐藏进度条的dom
  domShowFu("#UpAsyncLoding", false);
  progressDomFu("0%");
  // 初始化 上传附件 的状态
  setTimeout(() => {
    if (store.getState().A0Layout.closeUpFile.state)
      store.dispatch({
        type: "layout/closeUpFile",
        payload: { fu: () => {}, state: false },
      });
  }, 200);
};
